import { graphql } from 'gatsby';
import React from 'react';
import { Content, Header, Item, ItemList, Layout, SectionTitle, SEO, Wrapper } from '../components';

import { Repository } from '../components/Repository';
import PageProps from '../models/PageProps';
import { queryResultToPresentationList } from '../utils/contentList';

const OpenSourcePage = ({ data }: PageProps) => {
  const repos = queryResultToPresentationList(data, ['repo']);

  return(
    <Layout>
      <SEO isPost={false} titlePrefix={'Open Source |'} />
      <Header title='Open Source' icon='laptop' subHeading='Our Open Source repositories' />
      <ItemList>
      {repos.map((repo, idx) => <Item key={idx} {...repo} />)}
      </ItemList>
    </Layout>
  );
};
export default OpenSourcePage;

export const query = graphql`
  query {
    github {
      payload: search(query: "org:telia-oss", type: REPOSITORY, first: 100) {
        repositories: edges {
          repo: node {
            ... on GitHub_Repository {
              name
              description
              stargazers {
                totalCount
              }
              forks {
                totalCount
              }
              lastUpdated: updatedAt
              url
            }
          }
        }
      }
    }
  }
`;
